<template>
    <div>
        <section>
            <article class="the-reserve-article">
                <img src="../../../assets/images/acacia_mweya3.jpg" />
                <div class="the-reserve-article-description">
                    <div class="text-center">
                        <h2>Book now with benefits</h2>
                        <el-button 
                            class="acacia-btn mt-5"
                            @click="$router.push({path: 'accommodation'})"
                        >
                            BOOK NOW
                        </el-button>
                    </div>
                </div>
            </article>
        </section>
    </div>
  </template>
  
  <script>
  export default {
    components: {},
    data() {
      return {
  
      };
    },
    methods: {
      
    },
  };
  </script>
  
  <style scoped>
    
    .acacia-container {
        width: 100%;
        padding-left: 8%;
        padding-right: 8%;
    }
    
    .the-reserve-article {
    height: 200vh;
    width: 100%;
    position: relative;
    }

    .the-reserve-article img{
    height: 200vh;
    width: 100%;
    object-fit: cover;
    }

    .the-reserve-article-description {
    height: 200vh;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    }

    .the-reserve-article-description>div {
        width: 40%;
    }

    .the-reserve-article-description>div h2{
    color: white;
    font-size: 4em;
    font-weight: 800;
    text-transform: uppercase;
    }
  
    .acacia-btn {
        background-color: #17351E;
        border: 2px solid #17351E;
        color: #FFF;
        border-radius: 0px;
        font-size: 1.2em;
        margin: 0px !important;
        transition: 0.5s;
        width: 50%;
    }

    .acacia-btn:hover {
        background-color: #DDD0A9;
        border: 2px solid #DDD0A9;
        color: white !important;
        transition: 0.5s;
    }

    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
      
    }
  
    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
    }
  
    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {
  
    }
  
    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
                
        .the-reserve-article-description>div {
            width: 80%;
        }

        .the-reserve-article-description>div h2{
            font-size: 3em;
        }
    }
  </style>