<template>
  <div class="acacia_mweya-gallery">
    <section>
      <article class="acacia-container mt-5">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="text-center">
              <h1 style="font-size: 1.6em; font-weight: 600;">Capture the Beauty of Acacia Wilderness Mweya</h1>
              <p>
                Browse through our gallery and get a glimpse of the stunning scenery and wildlife that awaits you at Acacia Wilderness Mweya.
                From majestic elephants to picturesque sunsets over the savannah, our photos will transport you to the heart of nature.
              </p>
              <p>
                <strong>Get Inspired: </strong> Let our images inspire you next adventure with us. Witness the breathtaking landscapes and diverse 
                wildlife that make our lodge a true haven for nature lovers.
              </p>
              <hr/>
            </div>
          </div>
        </div>
        <div class="locus_gallery">
            <div class="demo-image__preview" v-for="(list, index ) in srcList" :key="index">
                <el-image 
                    :src="list" 
                    :preview-src-list="srcList">
                </el-image>
            </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>


export default {

  data() {
    return {
      
        srcList: [
            require("../../assets/images/acacia_mweya1.jpg"),
            require("../../assets/images/acacia_mweya2.jpeg"),
            require("../../assets/images/acacia_mweya3.jpg"),
            require("../../assets/images/acacia_mweya4.jpeg"),
            require("../../assets/images/acacia_mweya5.jpeg"),
            require("../../assets/images/acacia_mweya6.jpeg"),
            require("../../assets/images/acacia_mweya7.jpg"),
            
            require("../../assets/images/acacia_mweya8.jpeg"),
            require("../../assets/images/acacia_mweya9.jpeg"),
            require("../../assets/images/acacia_mweya10.jpg"),
        ],

    };
  },

  methods: {
  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.acacia-container {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
}
.acacia_mweya-gallery {
  padding-top: 200px;
}

.locus_gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 100px;
}

.demo-image__preview {
  background-color: #123b2f;
  height: 200px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 100px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 100px;
  }
  .acacia-container {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>

<style>
.locus_gallery .demo-image__preview .el-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.locus_gallery .demo-image__preview .el-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}
</style>
