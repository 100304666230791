<template>
  <div>
    <ParallaxComponent 
      :selectedImage="selectedImage"
      :title="'Delightful cuisine in an enchanting atmosphere'"
      :paragraph="''"
    />

    
    <section>
          <article class="the-best-alternative">
            <div class="container">
              <div class="the-best-alternative-grid">
                <div>
                  <div>
                    <h2>A superb dining experience</h2>
                    <p>A delight for the eyes and palate</p>
                  </div>
                </div>
                <div>
                  <div>
                    <p>
                      Dining at Acacia Wilderness Mweya is a journey of flavors, where each meal offers a taste of the wild beauty that surrounds 
                      you. Our gourmet dishes celebrate the finest local ingredients, artfully prepared to showcase the vibrant tastes of the 
                      region. Every bite is an experience, enhanced by a curated selection of wines and handcrafted cocktails, 
                      making each meal as unforgettable as the stunning wilderness views.  
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
    </section>

    <section>
      <article class="the-dining-slide-images">
        <div class="container">
          <el-carousel height="60vh" :interval="5000" arrow="always">
            <el-carousel-item>
              <div class="carousel_description">
                <img
                  src="../../assets/images/acacia_mweya6.jpeg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carousel_description">
                <img
                  src="../../assets/images/acacia_mweya7.jpg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </el-carousel-item> 
            <el-carousel-item>
              <div class="carousel_description">
                <img
                  src="../../assets/images/acacia_mweya8.jpeg"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </article>
    </section>

    <section>
      <section>
        <article>
          <div class="the-restaurant">
            <img src="../../assets/images/acacia_mweya3.jpg" />
            <div class="the-restaurant-content">
              <div class="container">
                <div class="the-content">
                  <h2>The Restaurant</h2>
                  <p class="sub-title">Discover the flavors of Uganda</p>
                  <p class="content-paragraph">
                    The lodge’s elegant dining area offers a spectacular space for a vibrant gourmet experience. 
                    Acacia Wilderness Mweya’s chefs draw inspiration from the rich culinary diversity of Uganda, 
                    making the most of the lodge’s location in the heart of Queen Elizabeth National Park, 
                    and providing guests with a taste of the country's finest flavors and traditions.
                  </p>
                  <el-button 
                        class="acacia-btn"
                        @click="$router.push({path: 'accommodation'})"
                        >VISIT THE LODGE</el-button
                      >
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
      <section>
        <article>
          <div class="room-accommodation-grid">
                <div>
                  <img src="../../assets/images/acacia_mweya7.jpg" />
                </div>
                <div>
                  <div>
                    <h2>OUR BAR</h2>
                    <p class="sub-title">A mastery of mixology</p>
                    <p class="content-paragraph">
                      It’s the perfect spot to meet new friends before dinner or enjoy a drink with your guide. 
                      The cocktail menu at Acacia Wilderness Mweya has been carefully crafted with the beauty of 
                      Queen Elizabeth National Park in mind. Come relax by the bar and explore our wide selection of exotic cocktails, 
                      all prepared by our expert mixologists. Afterward, pair your dinner with one of the fine wines from our 
                      extensive wine collection.  
                    </p>
                    
                    <div class="d-flex align-items-center">
                          <el-button 
                            class="acacia-btn-reverse"
                            @click="$router.push({path: 'accommodation'})"
                            >COCKTAIL MENU</el-button
                          >
                          <span class="mr-3"></span>
                          <el-button 
                            class="acacia-btn"
                            @click="$router.push({path: 'accommodation'})"
                            >WINE LIST</el-button
                          >
                    </div>
                  </div>
                </div>
          </div>
        </article>
      </section>
    </section>

    <section>
      <article class="dining-art">
        <div class="container d-flex align-items-center justify-content-center">
          <div class="dining-art-introduction text-center">
            <h2>East African cuisine: vibrant, rich, and unforgettable.</h2>
            <p class="sub-title">Featuring the Flavors of the Savanna</p>
            <p class="content-paragraph">
              Acacia Wilderness Mweya’s gastronomic identity is inspired by the native ingredients and vibrant flavors of the Ugandan landscape, 
              such as fresh herbs, locally sourced vegetables, and various organic products. Our cuisine combines modern techniques and 
              ongoing culinary exploration, all while honoring Uganda’s rich culinary heritage.
            </p>
            
            <el-button 
              class="acacia-btn-reverse"
              @click="$router.push({path: 'accommodation'})"
              >PLAN YOUR STAY</el-button
            >
          </div>
        </div>

        <div class="container d-flex align-items-center justify-content-center">
          <div class="dining-art-images">
            <div>
              <img src="../../assets/images/acacia_mweya7.jpg" />
            </div>
            <div>
              <img src="../../assets/images/acacia_mweya8.jpeg" />
            </div>
            <div>
              <img src="../../assets/images/acacia_mweya9.jpeg" />
            </div>
          </div>
        </div>
      </article>
    </section>

    <section>
        <article>
          <div class="room-accommodation-grid-reserve">
                <div>
                  <div>
                    <h2>Discover Our Menu</h2>
                    <p class="sub-title">Each dish is a whole new experience</p>
                    <p class="content-paragraph">
                      For breakfast, our guests can enjoy a delightful selection of fresh tropical fruits sourced from the region, 
                      various types of bread, local cheeses and hams, eggs prepared to your liking, cereals, homemade jams, organic honey, 
                      and granola. Pair this with invigorating fruit juices to energize you for the day's adventures.
                    </p>
                    <p class="content-paragraph">
                      Dinner is a more formal affair at Acacia Wilderness Mweya. The elegant setting of the dining area, 
                      accentuated by soft lighting, creates an intimate and romantic atmosphere perfect for savoring our chefs' creative and 
                      flavorful dishes. Prepare for a culinary journey through Uganda's diverse regions, all crafted with imagination and flair!
                    </p>
                    
                    <div class="d-flex align-items-center">
                          <el-button 
                            class="acacia-btn-reverse"
                            @click="$router.push({path: 'accommodation'})"
                            >COCKTAIL MENU</el-button
                          >
                          <span class="mr-3"></span>
                          <el-button 
                            class="acacia-btn"
                            @click="$router.push({path: 'accommodation'})"
                            >WINE LIST</el-button
                          >
                    </div>
                  </div>
                </div>
                <div>
                  <img src="../../assets/images/acacia_mweya7.jpg" />
                </div>
          </div>
        </article>
      </section>

    <ReservationArticleComponent />

  </div>
</template>

<script>
import ReservationArticleComponent from "../../components/website/home/reservation-article-component.vue";
  import ParallaxComponent from "../../components/website/pallarax-component.vue";
  export default {
  components: {
    ReservationArticleComponent,
    ParallaxComponent
  },

    data() {
      return {
        selectedImage: require("../../assets/images/desserts.webp"),
    };
  },
};
</script>

<style scoped>
  .dining-header {
      height: 100vh;
      width: 100%;
      position: relative;
  }
  .dining-header > div{
    background-color: rgba(0,0,0,0.3);
    color: white;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 180px;
  }
  .dining-header > div .dining-header-content{
    width: 50%;
  }
  .dining-header h1{
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 800;
  }
  .the-best-alternative {
    padding-top: 100px;
    padding-bottom: 100px;
    height: 100%;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div{
    height: 100%;
    display: flex;
    align-items: center;
  }
  .the-best-alternative-grid > div h2{
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 800;
    color: #17351E;
  }
  .the-best-alternative-grid > div p{
    font-size: 1.2em;
    font-weight: 300;
    color: #17351E;
  }
  .the-best-alternative-grid > div:nth-child(2) p{
    font-size: 1.2em;
    font-weight: 400;
  }
  .carousel_description img{
      object-fit: cover;
      object-position: bottom;
  }
  .the-dining-slide-images {
    padding-bottom: 100px;
  }
  .the-restaurant {
    height: 100vh;
    position: relative;
  }
  .the-restaurant img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  .the-restaurant .the-restaurant-content {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;

    display: flex;
    align-items: center;
  }
  .the-restaurant .the-restaurant-content .the-content{
    background-color: rgba(161,214,58,0.3);
    color: white;
    width: 50%;
    min-height: 60vh;
    padding: 50px;
  }
  .the-restaurant .the-restaurant-content .the-content h2{
    font-size: 2em;
    font-weight: 600;
  }
  .the-restaurant .the-restaurant-content .the-content .sub-title{
    font-size: 1.5em;
    font-weight: 500;
  }
  .the-restaurant .the-restaurant-content .the-content .content-paragraph{
    font-size: 1em;
    font-weight: 400;
  }

  .acacia-btn {
    background-color: transparent;
    color: white;
    border: 2px solid rgba(161,214,58,1);
    border-radius: 0px;
    font-size: 1.2em;
    margin: 0px !important;
    transition: 0.5s;
    width: 200px;
  }
  .acacia-btn:hover {
    background-color: rgba(161,214,58,1);
    color: black !important;
    transition: 0.5s;
  }
  .acacia-btn-inverse {
    background-color: #17351E;
    color: white !important;
    border: 2px solid #17351E;
    border-radius: 0px;
    font-size: 1.2em;
    margin: 0px !important;
    transition: 0.5s;
    width: 200px;
  }
  .acacia-btn-inverse:hover {
    background-color: transparent;
    color: #17351E;
    transition: 0.5s;
  }

  .acacia-btn-reverse {
    background-color: rgba(161,214,58,1);
    color: white !important;
    border: 2px solid rgba(161,214,58,1);
    border-radius: 0px;
    font-size: 1.2em;
    margin: 0px !important;
    transition: 0.5s;
    width: 200px;
  }

  .acacia-btn-reverse:hover {
    background-color: transparent;
    color: rgba(161,214,58,1);
    transition: 0.5s;
  }

  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 100px;
  }
  .room-accommodation-grid > div, .room-accommodation-grid-reserve > div{
    background-color: #17351E;
    color: white;
    height: 100%;
  }
  .room-accommodation-grid > div h2, .room-accommodation-grid-reserve > div h2{
    font-size: 2em;
    font-weight: 600;
    color: rgba(161,214,58,1);
  }
  .room-accommodation-grid > div .sub-title, .room-accommodation-grid-reserve > div .sub-title{
    font-size: 1.5em;
    font-weight: 500;
    color: rgba(161,214,58,1);
  }
  .room-accommodation-grid > div .content-paragraph, .room-accommodation-grid-reserve > div .content-paragraph{
    font-size: 1em;
    font-weight: 400;
  }
  .room-accommodation-grid > div img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 100px;
    display: flex;
    align-items: center;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) {
    height: 100%;
  }
  .room-accommodation-grid-reserve > div:nth-child(2) img{
    height: 90vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 100px;
  }
  .dining-art {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .dining-art-introduction {
    width: 80%;
  }
  .dining-art-introduction h2{
    font-size: 2em;
    font-weight: 600;
    color: rgba(161,214,58,1);
  }
  .dining-art-introduction .sub-title{
    font-size: 1.5em;
    font-weight: 500;
    color: rgba(161,214,58,1);
  }
  .dining-art-introduction .content-paragraph{
    font-size: 1em;
    font-weight: 400;
  }
  .dining-art-images {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .dining-art-images>div{
    height: 60vh;
  }
  .dining-art-images>div img{
    width: 100%;
    height: 60vh;
  }
  .dining-art-images >div:first-child{
    height: 60vh;
  }
  .dining-art-images >div:nth-child(2){
    position: relative;
  }
  .dining-art-images >div:nth-child(2) img{
    position: absolute;
    top: 60px;
    right: 30px;
  }
  .dining-art-images >div:nth-child(3){
    position: relative;
  }
  .dining-art-images >div:nth-child(3) img{
    position: absolute;
    right: 0px;
    width: 120%;
    height: 50vh;
    object-fit: cover;
  }
  .dining-art h2 {
    font-size: 3em;
    font-weight: 800;
    text-transform: uppercase;
  }
  
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
    
  .acacia-btn {
    width: auto;
  }
  .acacia-btn-inverse {
    width: auto;
  }
  .acacia-btn-reverse {
    width: auto;
  }
  .dining-header {
      height: 90vh;
  }
  .dining-header > div{
    padding-bottom: 180px;
  }
  .dining-header > div .dining-header-content{
    width: 90%;
  }
  .dining-header h1{
    text-transform: uppercase;
    font-size: 2.5em;
    font-weight: 800;
  }
  .the-best-alternative-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .the-best-alternative-grid > div h2{
    text-align: center;
  }
  .the-best-alternative-grid > div p{
    text-align: center;
  }
  .the-restaurant {
    min-height: 100vh;
    position: static;
  }
  .the-restaurant img{
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
  .the-restaurant .the-restaurant-content {
    position: static;
    width: 100%;
  }
  .the-restaurant .the-restaurant-content .the-content{
    width: 100%;
    min-height: 60vh;
    padding: 30px;
  }
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 100px;
  }
  .room-accommodation-grid > div, .room-accommodation-grid-reserve > div{
    background-color: #17351E;
    color: white;
    height: 100%;
  }
  .room-accommodation-grid > div h2, .room-accommodation-grid-reserve > div h2{
    font-size: 2em;
    font-weight: 600;
    color: rgba(161,214,58,1);
  }
  .room-accommodation-grid > div .sub-title, .room-accommodation-grid-reserve > div .sub-title{
    font-size: 1.5em;
    font-weight: 500;
    color: rgba(161,214,58,1);
  }
  .room-accommodation-grid > div .content-paragraph, .room-accommodation-grid-reserve > div .content-paragraph{
    font-size: 1em;
    font-weight: 400;
  }
  .room-accommodation-grid > div img{
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .room-accommodation-grid > div:nth-child(2){
    padding: 30px;
    display: flex;
    align-items: center;
  }
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr;
  }
  .room-accommodation-grid-reserve > div{
    height: 100vh;
  }
  .room-accommodation-grid-reserve > div img{
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .room-accommodation-grid-reserve > div:first-child{
    padding: 30px;
  }
  .dining-art {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .dining-art-introduction {
    width: 100%;
  }
  .dining-art-introduction h2{
    font-size: 2em;
    font-weight: 600;
    color: rgba(161,214,58,1);
  }
  .dining-art-introduction .sub-title{
    font-size: 1.5em;
    font-weight: 500;
    color: rgba(161,214,58,1);
  }
  .dining-art-introduction .content-paragraph{
    font-size: 1em;
    font-weight: 400;
  }

  .dining-art-images {
    margin-top: 100px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .dining-art-images>div{
    height: 40vh;
  }
  .dining-art-images>div img{
    width: 100%;
    height: 40vh;
  }
  .dining-art-images >div:first-child{
    height: 40vh;
  }
  .dining-art-images >div:nth-child(2){
    position: static;
  }
  .dining-art-images >div:nth-child(2) img{
    position: static;
  }
  .dining-art-images >div:nth-child(3){
    position: relative;
  }
  .dining-art-images >div:nth-child(3) img{
    position: absolute;
    bottom: 20vh;
    left: 50%;
    width: 120%;
    height: 50vh;
    object-fit: cover;
  }


}
</style>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>